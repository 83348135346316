import Navbar from "./components/Navbar";
import TopView from "./components/TopView";
import Predict from "./components/Predict";
import About from "./components/About";
import Footer from "./components/Footer";
import PBF from "./components/PBF";

function App() {
  return (
    <div>
      <Navbar />
      <TopView />
      <Predict />
      <PBF />
      <About />
      <Footer />
    </div>
  );
}

export default App;
