import React, { useRef, useState } from "react";
import Dropzone from "./DropZone";
import { FiArrowRight } from "react-icons/fi";
import styles from "./Predict.module.css";
import cuid from "cuid";
import { CSVLink } from "react-csv";

const Predict = () => {
  const mouseRef = useRef();

  const url = "https://www.ml-tunaotolith.toolmenlab.bime.ntu.edu.tw/predict";
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [images, setImages] = useState([]);
  const [scroll, setScroll] = useState({
    isScrolling: false,
    clientX: 0,
    scrollX: 0,
  });

  const onChangeHandler = (event) => {
    setIsLoading(true);
    setError(null);

    [...event.target.files].map((file) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        let time = Date.now();
        let data = {
          src: e.target.result.split(",")[1],
          filename: `${time}-${file.name}`,
          size: file.size,
          time: time,
        };
        fetch(url, {
          method: "POST",
          body: JSON.stringify(data),
          headers: new Headers({
            "Content-Type": "application/json",
          }),
        })
          .then((res) => res.json())
          .catch((error) => setError(error))
          .then((response) => {
            setImages((prevState) => [
              ...prevState,
              {
                id: cuid(),
                src: e.target.result,
                filename: file.name,
                size: file.size,
                result: response["prediction"],
                score: response["confident_score"],
              },
            ]);
          });
      };
      reader.readAsDataURL(file);
      return file;
    });
    setIsLoading(false);
  };

  const onResetHandler = () => {
    setImages([]);
  };

  const onMouseDownHandler = (e) => {
    setScroll((prevState) => {
      return {
        ...prevState,
        isScrolling: true,
        clientX: e.clientX,
      };
    });
  };

  const onMouseUpHandler = () => {
    setScroll((prevState) => {
      return {
        ...prevState,
        isScrolling: false,
      };
    });
  };

  const onMouseMoveHandler = (e) => {
    if (!scroll.isScrolling) return;
    e.preventDefault();
    mouseRef.current.scrollLeft =
      scroll.scrollX - 3 * (e.clientX - scroll.clientX);
    setScroll((prevState) => {
      return {
        ...prevState,
        scrollX: mouseRef.current.scrollLeft,
        clientX: e.clientX,
      };
    });
  };

  // const onWheelHandler = (e) => {
  //   document.body.classList.add("disable-scrolling");
  //   mouseRef.current.scrollLeft += e.deltaY;
  //   document.body.classList.remove("disable-scrolling");
  // };

  return (
    <div className={styles["container"]} id="predict">
      <p>Pacific Bluefin Tuna Age Predictor</p>
      {!isLoading && images.length > 0 && (
        <div className={styles["display-zone"]}>
          <div
            className={`${styles["horizontal-scroll"]}
            ${scroll.isScrolling && styles.active}`}
            ref={mouseRef}
            onMouseDown={onMouseDownHandler}
            onMouseUp={onMouseUpHandler}
            onMouseMove={onMouseMoveHandler}
            // onWheel={onWheelHandler}
          >
            {images.map((img) => (
              <div
                className={`${styles["image-box"]}
                ${scroll.isScrolling && styles.active}`}
              >
                <img key={img.id} src={img.src} alt={img.size}></img>
                <p>{img.filename}</p>
                <p>Age: {img.result}</p>
                {/* <p>Confident Score: {(img.score * 100).toFixed(2)}%</p> */}
              </div>
            ))}
            <div
              className={`${styles["dropzone-box"]}
                ${scroll.isScrolling && styles.active}`}
            >
              <Dropzone
                setImages={setImages}
                isEmpty={!(images.length > 0)}
                setIsLoading={setIsLoading}
                setError={setError}
                url={url}
              />
            </div>
          </div>
          <div className={styles["button-list"]}>
            <label className={styles["add-files"]} htmlFor="uploadfiles">
              <input
                id="uploadfiles"
                name="uploadfiles"
                type="file"
                multiple
                accept=".png, .jpg"
                onChange={onChangeHandler}
              />
              Add Files
            </label>
            <button className={styles["bt"]} onClick={onResetHandler}>
              Reset
            </button>
            <CSVLink
              className={styles["CSV"]}
              download="Otolith_age_prediction.csv"
              data={images.map(({ filename, result }) => ({
                filename,
                result,
              }))}
            >
              Export to CSV
            </CSVLink>
          </div>
        </div>
      )}
      {!isLoading && images.length === 0 && !error && (
        <Dropzone
          setImages={setImages}
          isEmpty={!(images.length > 0)}
          setIsLoading={setIsLoading}
          setError={setError}
          url={url}
        />
      )}
      {isLoading && (
        <div className={styles["lds-roller"]}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
      {!isLoading && error && <p>{error}</p>}
      <a
        className={styles["sample-image"]}
        href="https://drive.google.com/drive/folders/1dUFdyV3a2Qza3I1dSBZgYU07PCqEqEgH?usp=sharing"
        target="_blank"
        rel="noreferrer"
      >
        Sample images <FiArrowRight />
      </a>
    </div>
  );
};

export default Predict;
