import React from "react";
import { Link } from "react-scroll";
import styles from "./TopView.module.css";

const TopView = () => {
  return (
    <div className={styles["topView"]} id="TopView">
      <div className={styles["content"]}>
        <p>ML TunaOtolith</p>
        <p>
          Pacific Bluefin Tuna Age Determination Using Deep Learning and Otolith
          Image
        </p>
        <Link
          className={styles["button"]}
          to="predict"
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
        >
          Start
        </Link>
      </div>
    </div>
  );
};

export default TopView;
