import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./DropZone.module.css";
import cuid from "cuid";

const DropZone = (props) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      props.setIsLoading(true);
      props.setError(null);
      acceptedFiles.map((file) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          let time = Date.now();
          let data = {
            src: e.target.result.split(",")[1],
            filename: `${time}-${file.path}`,
            size: file.size,
            time: time,
          };
          fetch(props.url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: new Headers({
              "Content-Type": "application/json",
            }),
          })
            .then((res) => res.json())
            .catch((error) => props.setError(error))
            .then((response) => {
              props.setImages((prevState) => [
                ...prevState,
                {
                  id: cuid(),
                  src: e.target.result,
                  filename: file.path,
                  size: file.size,
                  result: response["prediction"],
                  score: response["confident_score"],
                },
              ]);
            });
        };
        reader.readAsDataURL(file);
        return file;
      });
      props.setIsLoading(false);
    },
    [props]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: ".png, .jpg" });

  return (
    <div
      {...getRootProps({
        className: `${styles["dropZone"]}
        ${isDragAccept && styles.isAccept}
        ${isDragReject && styles.isReject}
        ${!props.isEmpty && styles.notEmpty}`,
      })}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>accept</p>
      ) : (
        <p>
          Drag pacific blufin tuna otolith images here,
          <br /> or click to select
        </p>
      )}
    </div>
  );
};

export default DropZone;
