import React from "react";
import styles from "./About.module.css";

const About = () => {
  return (
    <div className={styles["about"]} id="about">
      <div className={styles["container"]}>
        <div className={styles["title"]}>
          <h4>Machine Learning and Machine Vision Lab</h4>
          <h5>Department of Biomechatronics Engineering</h5>
          <h5>National Taiwan University, Taiwan</h5>
        </div>
        <div className={styles["more"]}>
          <a
            href="https://www.mlmvlab.bime.ntu.edu.tw/"
            target="_blank"
            rel="noreferrer"
          >
            More
          </a>
        </div>
      </div>
      <div className={styles["container"]}>
        <div className={styles["title"]}>
          <h4>Quantitative Fisheries Lab</h4>
          <h5>Institute of Oceanography</h5>
          <h5>National Taiwan University, Taiwan</h5>
        </div>
        <div className={styles["more"]}>
          <a
            href="https://fisherylab.wixsite.com/qfish"
            target="_blank"
            rel="noreferrer"
          >
            More
          </a>
        </div>
      </div>
      <div className={styles["container"]}>
        <div className={styles["title"]}>
          <h4>Prof. Jen-Chieh Shiao's Lab</h4>
          <h5>Institute of Oceanography</h5>
          <h5>National Taiwan University, Taiwan</h5>
        </div>
        <div className={styles["more"]}>
          <a
            href="http://www.oc.ntu.edu.tw/oceng/?teacher=%e8%95%ad%e4%bb%81%e5%82%91"
            target="_blank"
            rel="noreferrer"
          >
            More
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
