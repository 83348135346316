import React from "react";
import styles from "./Footer.module.css";
import { Link } from "react-scroll";
import logo from "./images/logo.png";
import { FaPaperclip, FaPhone } from "react-icons/fa";
import { ImLocation2 } from "react-icons/im";
import { VscMail } from "react-icons/vsc";

const Footer = () => {
  return (
    <div className={styles["footer"]} id="contact">
      <div className={styles["logo"]}>
        <img src={logo} alt="logo" />
        <p>ML TunaOtolith</p>
      </div>
      <div className={styles["container"]}>
        <ul>
          <li className={styles["nav-item"]}>
            <Link
              to="TopView"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              Home
            </Link>
          </li>
          <li className={styles["nav-item"]}>
            <Link
              to="predict"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              Predict
            </Link>
          </li>
          <li className={styles["nav-item"]}>
            <Link to="PBF" spy={true} smooth={true} offset={50} duration={500}>
              PBF
            </Link>
          </li>
          <li className={styles["nav-item"]}>
            <Link
              to="about"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              About
            </Link>
          </li>
        </ul>
        <div className={styles["bottom"]}>
          <a
            className={styles["icon"]}
            href="https://www.mlmvlab.bime.ntu.edu.tw/"
            target="_blank"
            rel="noreferrer"
          >
            <FaPaperclip />
            <p>Paper</p>
          </a>
          <a className={styles["icon"]} href="mailto: b07611035@ntu.edu.tw">
            <VscMail />
            <p>E-mail</p>
          </a>
          <a className={styles["icon"]} href="tel:+886233665365">
            <FaPhone />
            <p>Phone</p>
          </a>
          <a
            className={styles["icon"]}
            href="https://www.google.com.tw/maps/place/%E5%9C%8B%E7%AB%8B%E8%87%BA%E7%81%A3%E5%A4%A7%E5%AD%B8%E7%94%9F%E7%89%A9%E6%A9%9F%E9%9B%BB%E5%B7%A5%E7%A8%8B%E5%AD%B8%E7%B3%BB/@25.0177019,121.5424841,18z/data=!4m5!3m4!1s0x3442aa2429043393:0xcfa54254447302e2!8m2!3d25.017933!4d121.5426678?hl=zh-TW"
            target="_blank"
            rel="noreferrer"
          >
            <ImLocation2 />
            <p>Location</p>
          </a>
        </div>
        <p>© 2023 Tsung-Hsiang Ma. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
