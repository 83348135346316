import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-scroll";

import styles from "./Navbar.module.css";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  const closeMenu = () => setClick(false);

  return (
    <div
      className={
        color ? `${styles["header"]} ${styles["header-bg"]}` : styles["header"]
      }
    >
      <nav className={styles["navbar"]}>
        <Link
          className={styles["nav-item"]}
          to="TopView"
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
        >
          ML TunaOtolith
        </Link>
        <div className={styles["hamburger"]} onClick={handleClick}>
          {click ? (
            <FaTimes size={30} style={{ color: "#ffffff" }} />
          ) : (
            <FaBars size={30} style={{ color: "#ffffff" }} />
          )}
        </div>
        <ul
          className={
            click
              ? `${styles["nav-menu"]} ${styles["active"]}`
              : styles["nav-menu"]
          }
        >
          <li className={styles["nav-item"]}>
            <Link
              onClick={closeMenu}
              to="predict"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              Predict
            </Link>
          </li>
          <li className={styles["nav-item"]}>
            <Link
              onClick={closeMenu}
              to="PBF"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              PBF
            </Link>
          </li>
          <li className={styles["nav-item"]}>
            <Link
              onClick={closeMenu}
              to="about"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              About
            </Link>
          </li>

          <li className={styles["nav-item"]}>
            <Link
              onClick={closeMenu}
              to="contact"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
