import styles from "./PBF.module.css";
import PBF_img from "./images/PBF.png";
import Otolith from "./images/Otolith.png";

const PBF = () => {
  return (
    <div className={styles["PBF"]}>
      <div className={styles["info"]}>
        <div className={styles["data"]}>
          <p>±1-accuracy</p>
          <p>
            72.81<small> %</small>
          </p>
        </div>
        <div className={styles["data"]}>
          <p>CV</p>
          <p>
            7.55<small> %</small>
          </p>
        </div>
        <div className={styles["data"]}>
          <p>Predictable Age</p>
          <p>4 ~ 27</p>
        </div>
        <div className={styles["data"]}>
          <p>Data Amount</p>
          <p>3261</p>
        </div>
      </div>
      <div className={styles["container"]} id="PBF">
        <div className={styles["card"]}>
          <img
            className={styles}
            src={PBF_img}
            alt="Sorry, your browser does not support svg."
          />
          <h3>Pacific Bluefin Tuna</h3>
          <p>
            Pacific bluefin tuna, <i>Thunnus orientalis</i>, is an high
            commercial value species, which makes it a species targeted at all
            life stages by several countries. However, the PBF biomass had
            decreased to its historically lowest level in 2010 due to
            overfishing. Although the PBF stock has started recovering in the
            recent 5 years, routine long-term monitoring of the age structure of
            catch and stock is needed so that the demographic changes of the PBF
            can be understood in a timely manner.
          </p>
        </div>
        <div className={styles["card"]}>
          <img src={Otolith} alt="Sorry, your browser does not support svg." />
          <h3>Otolith</h3>
          <p>
            To monitor demographic changes, otolith, a calcium carbonate
            structure inside the inner ear of fish, is the most effective
            material for fish age estimation. Fish age can be determine by
            counting the number of alternating opaque and translucent zones.
            Although simple in principle, age determination is often faced with
            challenges of unclear nucleus and rings, crowding rings as fish grow
            older, and false annual rings, which makes the age determination
            process a difficult and time-consuming task.
          </p>
        </div>
      </div>
    </div>
  );
};
export default PBF;
